import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import React, { Component, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  ZoneOmega,
} from "react-native";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";

import { Avatar, Button, Card, Title, Paragraph } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import { useSelector } from "react-redux";
import moment from "moment";
import { postData } from "../../networking/api";
import { projectTypeValueById } from "./getProjectTypeValue";

export default function ({ navigation, route }) {
  const { Zoneid, ZoneName } = route?.params;

  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [zoneGridDetails, setZoneGridDetails] = useState([]);
  const [projectTypeValue, setProjectTypeValueData] = useState("IRRIGATION");

  const DATA = [
    {
      id: "bd7acbea-c1b1-46c2-aed5-3ad53abb28ba",
      title: "Next Irrigation",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "3ac68afc-c605-48d3-a4f8-fbd91aa97f63",
      title: "Last Irrigation",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "58694a0f-3da1-471f-bd96-145571e29d72",
      title: "Last Connection",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "bd7acbea-c1b1-46c2-aed5-3ad53abb28ba",
      title: "fourth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "3ac68afc-c605-48d3-a4f8-fbd91aa97f63",
      title: "fifth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
    {
      id: "58694a0f-3da1-471f-bd96-145571e29d72",
      title: "sixth Item",
      level: "12568 m³/h",
      wifi: "12/100",
    },
  ];

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      // searchFilterFunction("");
      // console.log("projectName gridview", projectName);
    }, [projectId, projectName])
  );

  const getAdminData = async () => {
    const projectTypeValue = await projectTypeValueById();
    setProjectTypeValueData(projectTypeValue);

    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proName = await AsyncStorage.getItem("projectName");

    setProjectName(JSON.parse(proName));
    let proCode = JSON.parse(proCodeId);
    // setProjectCode(proCode);
    // console.log("proCodeId:&& proName", proCode, proName);
    let projValue = await AsyncStorage.getItem("projectData");

    // console.log("jsonValue & projValue:", jsonValue, projValue, proCodeId);
    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      setProjectId(projValue);
      getZoneGridMonitor(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getZoneGridMonitor(userDetailsresponse.project_selected);
    }
  };

  //Get details when click on card id
  const getZoneGridMonitor = async (proId) => {
    // console.log("proId===", proId);
    setIsLoadingSync(true);
    try {
      var OmegaDetails = JSON.stringify({
        // omega_id: "clahuvov851900yu4aikt9sxv",
        project_id: proId,
        zone_id: Zoneid,
      });
      // console.log("subZoneMonitorGridView payload", OmegaDetails);
      const zoneMonitorGridViewResponse = await postData(
        OmegaDetails,
        "gridview/subZoneMonitoringData"
      );
      // console.log("====================================");
      // console.log("subZoneMonitorGridView", zoneMonitorGridViewResponse);
      // console.log("====================================");
      setIsLoadingSync(false);
      var code = zoneMonitorGridViewResponse.code;
      if (code == 1) {
        console.log("ZoneView....", zoneMonitorGridViewResponse.data);
        setZoneGridDetails(zoneMonitorGridViewResponse.data);
      } else {
        setIsLoadingSync(false);
        // //alert(zoneMonitorGridViewResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  const screenwidth = Dimensions.get("window").width;
  const isMobile = screenwidth < 768;

  const renderItem = ({ item, index }) => {
    // console.log("item.issubsubzone", item.issubsubzone, item.issubsubzone != false, item.issubsubzone == false);
    //make floating number round - RohitB
    const decimalPart = item.flow - Math.floor(item.flow);
    const shouldRoundUp = decimalPart > 0.5;
    const roundedValue = shouldRoundUp
      ? Math.ceil(item.flow)
      : Math.floor(item.flow);
    console.log("item.isonline", item.isonline);

    return (
      // <View style={{ flexDirection: "column" }}>

      <View
        style={
          isMobile
            ? {
                width: "54%",
                marginVertical: 5,
                padding: 10,
                borderRadius: 10,
                marginLeft: -5,
              }
            : [styles.card]
        }
      >
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "#009df01f",
            },
          ]}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, margin: 10 }}>
              <Image
                style={{
                  height: 35,
                  width: 25,
                  resizeMode: "contain",
                }}
                source={
                  item.issubsubzone
                    ? ""
                    : require("../../assets/images/omega.png")
                }
              />
              {/* <Image style={{}} /> */}
            </View>
            <View style={{ flex: 5, margin: 10 }}>
              <Text
                style={{ fontSize: "20", fontWeight: "700" }}
                // onPress={() => {
                //   navigation.navigate("ValveView", {
                //     OmegaId: item.omega_id,
                //     OmegaName: item.device_name
                //   });
                // }}

                onPress={() => {
                  item.issubsubzone
                    ? ""
                    : navigation.navigate("ValveView", {
                        //[item.based_on == 2 ? 'IMEIId' : 'OmegaId']: item.omega_id,
                        OmegaId: item.omega_id,
                        OmegaName: item.device_name,
                        basedOn: item.based_on,
                        //DeviceId: item.based_on === 2 ? item.device_id : ' ',
                      });
                }}
              >
                {!item.issubsubzone ? item.device_name : item.sub_zone_name}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Icon
                name={item.issubsubzone ? "" : "wifi"}
                color={item.isonline == true ? "#228B22" : "#FF0000"}
                size={20}
              />
            </View>
          </View>
          {item.issubsubzone ? (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {/* {item.nextIrrigationTime} */}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Next " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.based_on == 2
                    ? item.nextIrrigationTime
                      ? item.nextIrrigationTime
                      : "-"
                    : item.nextIrrigationTime == null ||
                      item.nextIrrigationTime == ""
                    ? "-"
                    : item.nextIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Last " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.based_on == 2
                    ? item.lastIrrigationTime
                      ? item.lastIrrigationTime
                      : "-"
                    : item.lastIrrigationTime == null ||
                      item.lastIrrigationTime == ""
                    ? "-"
                    : item.lastIrrigationTime}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {
                    item.isonline == true
                      ? "Online"
                      : // item.lastConnection == null || item.lastConnection == "" ? "" :
                        item.lastConnection
                    // (moment(item.lastConnection).format(
                    //   "DD/MM/YYYY h:mma"
                    // ))
                  }
                </Text>
              </View>
            </View>
          )}
          <View style={{ flexDirection: "row" }}>
            {/* <View style={{ flex: 1, margin: 10 }}>
                <Icon name="cloud-download-alt" color="#000" size={20} />
              </View> */}
            <View style={{ flex: 1, margin: 10, maxWidth: 150 }}>
              <Text>
                {" "}
                {item.omega_id !== null && (
                  <Image
                    style={{
                      height: 17,
                      width: 25,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/valves.png")}
                  />
                )}
                {item.valve_name == undefined ? "" : item.valve_name.join(", ")}{" "}
                {/* {item.valve_name}{" "} */}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              {item.issubsubzone ? (
                " "
              ) : (
                <Text>
                  {item.error}{" "}
                  <IconMaterial name="bell-badge" color="red" size={20} />
                </Text>
              )}
            </View>
          </View>
          {item.issubsubzone && (
            <View style={{ flexDirection: "row", backgroundColor: "#D4E3EB" }}>
              <View style={{ flex: 1.8, margin: 10 }}>
                <Text>
                  {" "}
                  <View
                    style={
                      isMobile
                        ? { flexDirection: "row", marginLeft: 5 }
                        : { flexDirection: "row" }
                    }
                  >
                    {" "}
                    <Icon
                      name="wifi"
                      color="#228B22"
                      size={15}
                      style={isMobile ? { marginLeft: -10 } : null}
                    />
                    <Text style={isMobile ? { marginLeft: -1 } : null}>
                      {" "}
                      {item.isOnlineCount}
                    </Text>
                    <Text>/{item.deviceCount} </Text>
                  </View>
                  <View
                    style={
                      isMobile
                        ? { flexDirection: "row", marginLeft: 5 }
                        : {
                            flexDirection: "row",
                            marginLeft: 10,
                            marginTop: -15,
                          }
                    }
                  >
                    <Icon
                      name="water"
                      color="#009DF0"
                      size={15}
                      style={
                        isMobile
                          ? { marginLeft: -5, marginTop: 2, marginRight: 5 }
                          : { marginRight: 10 }
                      }
                    />{" "}
                    <View
                      style={
                        isMobile
                          ? { flexDirection: "row" }
                          : { flexDirection: "row", marginTop: -2 }
                      }
                    >
                      <Text>{roundedValue}</Text>
                      <Text style={{ marginLeft: 5 }}>m³/hr</Text>
                    </View>
                  </View>
                  {/* <Icon name="wifi" color="#228B22" size={15} />{" "}
                  {item.isOnlineCount} / {item.deviceCount}{" "}
                  <Icon name="water" color="#009DF0" size={15} /> {item.flow} m³/hr */}
                </Text>
              </View>

              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Text
                  onPress={() => {
                    navigation.navigate("Subzone", {
                      Zoneid: Zoneid,
                      ZoneName: ZoneName,
                      SubzoneId: item.sub_zone_id,
                      SubZoneName: item.sub_zone_name,
                      OmegaName: item.device_name,
                    });
                    // navigation.navigate("ValveView", {Zoneid: Zoneid });
                  }}
                >
                  {" "}
                  <IconMaterial
                    name="file-move-outline"
                    color="#000"
                    size={20}
                  />
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
      // </View>
    );
  };

  return (
    <View style={styles.MainContainer}>
      <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"}
            <Text onPress={() => navigation.navigate("GridView")}>
              Grid View
            </Text>
            {">"} {project_name} {">"} {ZoneName}
          </Text>
        </View>
      </View>

      <View>
        {isLoadingSync ? (
          <ActivityIndicator
            //visibility of Overlay Loading Spinner
            color="#1DA1F2"
            visible={isLoadingSync}
            //Text with the Spinner
            textContent={"Loading..."}
            size={100}
            //  //Text style of the Spinner Text
            //  textStyle={styles.spinnerTextStyle}
          />
        ) : (
          <View
            style={{
              flexDirection: "row",
              marginTop: "0%",
              flexWrap: "wrap-reverse",
            }}
          >
            {/* <View style={{ flex: 1, margin: 10 }}>
                              <Text>Monitor {">"} Grid View {">"} {project_name} </Text>
                          </View> */}
            {zoneGridDetails.length > 0 ? (
              // zoneGridDetails.map((item, index) => {
              //   return renderItem(item, index);
              // })
              <FlatList
                numColumns={isMobile ? 2 : 4}
                // style={{ width: "75%" }}
                contentContainerStyle={{
                  padding: 5,
                }}
                data={zoneGridDetails}
                renderItem={renderItem}
              />
            ) : (
              <View style={{ justifyContent: "center" }}>
                <Text
                  style={[
                    styles.emptyListStyle,
                    { alignSelf: "center", marginTop: 20 },
                  ]}
                >
                  No Zone Data Found{" "}
                </Text>
              </View>
            )}

            {/* {zoneGridDetails.map((item, index) => {
              return renderItem(item, index);
            })} */}
          </View>
        )}
      </View>
      {/* <View
        style={{
          flexDirection: "row",
          marginTop: "0%",
          flexWrap: "wrap-reverse",
        }}
      >

        {zoneGridDetails.map((item, index) => {
          return renderItem(item, index);
        })}
      </View> */}
    </View>
  );
}

const styles = StyleSheet.create({
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
    padding: 20,
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  card: {
    width: "25%",
    // flex: 0.5,

    marginVertical: 10,
    padding: 10,
    // flexDirection: 'row'
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    // marginTop: 18,
    // fontSize: 21,
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },
});
