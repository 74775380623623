import AsyncStorage from "@react-native-async-storage/async-storage";
import { postData } from "../networking/api";

const fetchProjectBasedOn = async () => {
  try {
    let projValue = await AsyncStorage.getItem("projectCode");
    if (!projValue) {
      return 1;
    }
    console.log("codeprojValuee", projValue);

    // Create ProfileData object
    const ProfileData = JSON.stringify({
      module: "project",
      condition: { project_code: JSON.parse(projValue) },
    });

    const ProjectProfileData = await postData(
      ProfileData,
      "/masters/getMasterList"
    );
    if (
      ProjectProfileData?.status === 200 &&
      ProjectProfileData?.data?.length > 0
    ) {
      return ProjectProfileData.data[0].based_on;
    }

    return 1;
  } catch (error) {
    console.log("Error fetching project data:", error);
    return 1;
  }
};

// Export the promise directly
export const projectBasedOn = fetchProjectBasedOn();
