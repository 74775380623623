import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";
import moment from "moment";
import React, { Component, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import { Avatar, Button, Card, Title, Paragraph } from "react-native-paper";
import Icon from "react-native-vector-icons/FontAwesome5";
import IconMaterial from "react-native-vector-icons/MaterialCommunityIcons";

import { useSelector } from "react-redux";
import { postData, getMethod, baseUrl } from "../../networking/api";
import { projectTypeValueById } from "./getProjectTypeValue";

export default function GridViewOmega({ navigation }) {
  var projectName = useSelector((state) => state.projectName);
  const [project_name, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [zoneGridDetails, setZoneGridDetails] = useState([]);
  const [projectWiseGridViewDetails, setProjectWiseGridViewDetails] = useState(
    []
  );
  const [projectTypeValue, setProjectTypeValueData] = useState("IRRIGATION");

  useFocusEffect(
    React.useCallback(() => {
      getAdminData();
      getLiveData();
      // searchFilterFunction("");
      // console.log("projectName gridview", projectName);
    }, [projectName])
  );

  const getAdminData = async () => {
    const projectTypeValue = await projectTypeValueById();
    setProjectTypeValueData(projectTypeValue);

    let jsonValue = await AsyncStorage.getItem("userData");
    let proCodeId = await AsyncStorage.getItem("projectCode");
    let proName = await AsyncStorage.getItem("projectName");

    setProjectName(JSON.parse(proName));
    let proCode = JSON.parse(proCodeId);
    // setProjectCode(proCode);
    // console.log("proCodeId:&& proName", proCode, proName);
    let projValue = await AsyncStorage.getItem("projectData");

    if (projValue != null) {
      // console.log("project id select in zone create", projValue, proCodeId);
      setProjectId(projValue);
      getZoneGridMonitor(projValue);
      getProjectwiseGridViewMonitor(projValue);
      //   getOmegaDependentByProjectId(projValue);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      // console.log("userDetailsresponse in zone ==>", userDetailsresponse);
      // setloginId(userDetailsresponse.email_id);
      // setProjectId("cl86ts8rj217140xp5138s7iqm")

      setProjectId(userDetailsresponse.project_selected);
      // setEmpCode(userDetailsresponse.employee_code);
      getZoneGridMonitor(userDetailsresponse.project_selected);
      getProjectwiseGridViewMonitor(userDetailsresponse.project_selected);
    }
  };

  const getLiveData = async () => {
    if (projectId) {
      const payload = JSON.stringify({
        // omega_id: "clahuvov851900yu4aikt9sxv",
        project_id: projectId,
      });
      await postData(payload, "masters/liveMonitoring");
    }
  };
  //Get details when click on card id
  const getZoneGridMonitor = async (proId) => {
    // console.log("proId===", proId);
    setIsLoadingSync(true);
    try {
      var OmegaDetails = JSON.stringify({
        // omega_id: "clahuvov851900yu4aikt9sxv",
        project_id: proId,
      });
      // console.log("OmegaDetails", OmegaDetails);
      const zoneMonitorGridViewResponse = await postData(
        OmegaDetails,
        "gridview/zoneMonitoringData"
      );
      // console.log("====================================");
      // console.log("zoneMonitorGridView", zoneMonitorGridViewResponse);
      // console.log("====================================");
      setIsLoadingSync(false);
      var code = zoneMonitorGridViewResponse.code;
      if (code == 1) {
        setZoneGridDetails(zoneMonitorGridViewResponse.data);
      } else {
        setIsLoadingSync(false);
        // //alert(zoneMonitorGridViewResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  //Get project wise grid view details
  const getProjectwiseGridViewMonitor = async (proId) => {
    console.log("proId==>", proId);
    setIsLoadingSync(true);
    try {
      setIsLoadingSync(false);
      fetch(baseUrl + "dashboards/projectwiseGridView/" + proId, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((data) => {
          return data.json();
        })
        .catch((error) => {
          console.log(error);
        })
        .then((response) => {
          var code = response.code;
          console.log("response.data", response.data);
          if (code == 1) {
            setProjectWiseGridViewDetails(response.data);
          } else {
            setIsLoadingSync(false);
          }
        });
    } catch (error) {
      setIsLoadingSync(false);
      console.log(error);
    }
  };
  const screenwidth = Dimensions.get("window").width;
  const isMobile = screenwidth < 768;
  const renderItem = ({ item, index }) => {
    console.log("item zone grid view", item);
    //make floating number round - RohitB
    const decimalPart = item.flow - Math.floor(item.flow);
    const shouldRoundUp = decimalPart > 0.5;
    const roundedValue = shouldRoundUp
      ? Math.ceil(item.flow)
      : Math.floor(item.flow);

    return (
      <View
        style={
          isMobile
            ? {
                width: "54%",
                marginVertical: 5,
                padding: 10,
                borderRadius: 10,
                marginLeft: -5,
              }
            : [styles.card]
        }
      >
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "#009df01f",
            },
          ]}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, margin: 10 }}>
              <Image
                style={{
                  height: 35,
                  width: 25,
                  resizeMode: "contain",
                }}
                source={
                  item.omega_id === null
                    ? ""
                    : require("../../assets/images/omega.png")
                }
              />
              {/* <Image style={{}} /> */}
            </View>
            <View style={{ flex: 5, margin: 10 }}>
              <Text
                onPress={() => {
                  item.omega_id === null
                    ? ""
                    : navigation.navigate("ValveView", {
                        OmegaId: item.omega_id,
                        OmegaName: item.device_name,
                      });
                  // console.log("omega id ", item.omega_id);
                }}
                style={
                  isMobile
                    ? {
                        justifyContent: "flex-start",
                        fontSize: "20",
                        fontWeight: "700",
                        marginLeft: -6,
                      }
                    : {
                        justifyContent: "center",
                        fontSize: "20",
                        fontWeight: "700",
                        marginLeft: 60,
                      }
                }
              >
                {item.zone_name}
              </Text>
            </View>

            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Icon
                name={item.omega_id === null ? "" : "wifi"}
                color={item.isonline == true ? "#228B22" : "#FF0000"}
                size={20}
              />
            </View>
          </View>
          {item.omega_id === null ? (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {/* {item.nextIrrigationTime} */}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>

                <Text
                  style={{
                    // backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Next " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.nextIrrigationTime == null ||
                  item.nextIrrigationTime == ""
                    ? ""
                    : moment(item.nextIrrigationTime).format(
                        "DD/MM/YYYY HH:MM:SS"
                      )}
                  {/* {item.nextIrrigationTime} */}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Last " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.lastIrrigationTime == null ||
                  item.lastIrrigationTime == ""
                    ? ""
                    : moment(item.lastIrrigationTime).format(
                        "DD/MM/YYYY HH:MM:SS"
                      )}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {
                    item.isonline == true
                      ? "Online"
                      : item.lastConnection == null || item.lastConnection == ""
                      ? ""
                      : moment(item.lastConnection).format("DD/MM/YYYY h:mma")
                    // item.lastIrrigationName
                  }
                </Text>
              </View>
            </View>
          )}
          <View style={{ flexDirection: "row" }}>
            {/* <View style={{ flex: 1, margin: 10 }}>
                <Icon name="cloud-download-alt" color="#000" size={20} />
              </View> */}
            <View style={{ flex: 4, margin: 10, maxWidth: 185 }}>
              <Text>
                {" "}
                {item.omega_id !== null && (
                  <Image
                    style={{
                      height: 17,
                      width: 25,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/valves.png")}
                  />
                )}
                {item.valve_name == undefined ? "" : item.valve_name.join(", ")}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              {item.omega_id === null ? (
                ""
              ) : (
                <Text>
                  {" "}
                  {item.error}{" "}
                  <IconMaterial name="bell-badge" color="red" size={20} />
                </Text>
              )}
            </View>
          </View>
          <View style={{ flexDirection: "row", backgroundColor: "#D4E3EB" }}>
            <View style={{ flex: 1.8, margin: 10 }}>
              <View
                style={
                  isMobile
                    ? { flexDirection: "row", marginLeft: 5 }
                    : { flexDirection: "row" }
                }
              >
                {" "}
                <Icon
                  name="wifi"
                  color="#228B22"
                  size={15}
                  style={isMobile ? { marginLeft: -10 } : null}
                />
                <Text style={isMobile ? { marginLeft: -1 } : null}>
                  {" "}
                  {item.isOnlineCount}
                </Text>
                <Text>/{item.deviceCount} </Text>
              </View>
              <View
                style={
                  isMobile
                    ? { flexDirection: "row" }
                    : { flexDirection: "row", marginLeft: 65, marginTop: -17 }
                }
              >
                <Icon
                  name="water"
                  color="#009DF0"
                  size={15}
                  style={
                    isMobile
                      ? { marginLeft: -5, marginTop: 2, marginRight: 5 }
                      : { marginRight: 10 }
                  }
                />{" "}
                {/* <View style={isMobile ? {flexDirection:"row",marginLeft:1} : {}}>
              <Text style={isMobile ? {} : {marginTop:-2}}>{Math.ceil(item.flow)}</Text><Text> </Text><Text style={isMobile ? {} : {marginTop:-19, marginLeft:10}}> m³/hr</Text>
              </View> */}
                <View
                  style={
                    isMobile
                      ? { flexDirection: "row" }
                      : { flexDirection: "row", marginTop: -2 }
                  }
                >
                  <Text>{roundedValue}</Text>
                  <Text style={{ marginLeft: 5 }}>m³/hr</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Text
                onPress={() => {
                  // console.log("item redirect to zone", item);
                  // item.omega_id === null ? "" : navigation.navigate("Zone", {
                  //   Zoneid: item.zone_id,
                  //   ZoneName: item.zone_name,
                  // });
                  navigation.navigate("Zone", {
                    Zoneid: item.zone_id,
                    ZoneName: item.zone_name,
                  });
                }}
              >
                {" "}
                <IconMaterial name="file-move-outline" color="#000" size={20} />
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  const renderProjectwiseGridViewItem = () => {
    var item = projectWiseGridViewDetails[0];
    console.log("projectWiseGridViewDetails[0]", projectWiseGridViewDetails[0]);
    return (
      <View style={[styles.card]}>
        <View
          style={[
            styles.shadowProp,
            {
              backgroundColor: "#009df01f",
            },
          ]}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, margin: 10 }}>
              <Image
                style={{
                  height: 35,
                  width: 25,
                  resizeMode: "contain",
                }}
                source={
                  item.omega_id === null
                    ? ""
                    : require("../../assets/images/omega.png")
                }
              />
            </View>
            <View style={{ flex: 5, margin: 10 }}>
              <Text
                style={{ fontSize: "20", fontWeight: "700" }}
                onPress={() => {
                  item.omega_id === null
                    ? ""
                    : navigation.navigate("ValveView", {
                        OmegaId: item.omega_id,
                        OmegaName: item.device_name,
                      });
                }}
              >
                {item.device_name}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              <Icon
                name={item.omega_id === null ? "" : "wifi"}
                color={item.isonline == true ? "#228B22" : "#FF0000"}
                size={20}
              />
            </View>
          </View>
          {item.omega_id === null ? (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                ></Text>
                <Text
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                ></Text>
              </View>
            </View>
          ) : (
            <View style={{ flexDirection: "column", padding: 5 }}>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Next " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.nextIrrigationTime == null ||
                  item.nextIrrigationTime == ""
                    ? ""
                    : moment(item.nextIrrigationTime).format(
                        "DD/MM/YYYY HH:MM:SS"
                      )}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  {"Last " + projectTypeValue}
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.lastIrrigationTime == null ||
                  item.lastIrrigationTime == ""
                    ? ""
                    : moment(item.lastIrrigationTime).format(
                        "DD/MM/YYYY HH:MM:SS"
                      )}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                  }}
                >
                  Last Connection
                </Text>

                <Text
                  style={{
                    backgroundColor: "#D4E3EB",
                    width: "100%",
                    marginBottom: 10,
                    padding: 8,
                    justifyContent: "flex-end",
                  }}
                >
                  {item.isonline == true
                    ? "Online"
                    : item.lastConnection == null || item.lastConnection == ""
                    ? ""
                    : // item.lastIrrigationName
                      moment(item.lastConnection).format("DD/MM/YYYY h:mma")}
                </Text>
              </View>
            </View>
          )}
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 1, margin: 10, maxWidth: 150 }}>
              <Text>
                {" "}
                {item.omega_id !== null && (
                  <Image
                    style={{
                      height: 17,
                      width: 25,
                      resizeMode: "contain",
                    }}
                    source={require("../../assets/images/valves.png")}
                  />
                )}
                {item.valve_name == undefined ? "" : item.valve_name.join(", ")}
              </Text>
            </View>
            <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
              {item.omega_id === null ? (
                " "
              ) : (
                <Text>
                  {item.error}{" "}
                  <IconMaterial name="bell-badge" color="red" size={20} />
                </Text>
              )}
            </View>
          </View>
          {item.issubsubzone === "false" && (
            <View style={{ flexDirection: "row", backgroundColor: "#D4E3EB" }}>
              <View style={{ flex: 1.8, margin: 10 }}>
                <Text>
                  {" "}
                  <Icon name="wifi" color="#228B22" size={15} />{" "}
                  {item.isOnlineCount} / {item.deviceCount}{" "}
                  <Icon name="water" color="#009DF0" size={15} /> {item.flow}{" "}
                  m³/hr
                </Text>
              </View>

              <View style={{ flex: 1, alignItems: "flex-end", margin: 10 }}>
                <Text
                  onPress={() => {
                    navigation.navigate("Subzone", {
                      Zoneid: Zoneid,
                      ZoneName: ZoneName,
                      SubzoneId: item.sub_zone_id,
                      SubZoneName: item.sub_zone_name,
                      OmegaName: item.device_name,
                    });
                  }}
                >
                  {" "}
                  <IconMaterial
                    name="file-move-outline"
                    color="#000"
                    size={20}
                  />
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
      // </View>
    );
  };

  return (
    <View style={styles.MainContainer}>
      {/* <View style={styles.container}> */}
      <View style={{ flexDirection: "row", width: "100%", marginTop: "0%" }}>
        <View style={{ flex: 1, margin: 10 }}>
          <Text style={styles.lableText}>
            Monitor {">"} Grid View {">"} {project_name}{" "}
          </Text>
        </View>
      </View>
      <View>
        {isLoadingSync ? (
          <ActivityIndicator
            //visibility of Overlay Loading Spinner
            color="#1DA1F2"
            visible={isLoadingSync}
            //Text with the Spinner
            textContent={"Loading..."}
            size={100}
            //  //Text style of the Spinner Text
            //  textStyle={styles.spinnerTextStyle}
          />
        ) : (
          <View
            style={{
              marginTop: "0%",
            }}
          >
            {projectWiseGridViewDetails.length > 0
              ? renderProjectwiseGridViewItem()
              : // <View>
                //   <FlatList
                //     numColumns={4}
                //     // style={{ width: "75%" }}
                //     contentContainerStyle={{
                //       padding: 5,
                //     }}
                //     data={projectWiseGridViewDetails}
                //     renderItem={renderProjectwiseGridViewItem}
                //   />
                // </View>
                null}

            {zoneGridDetails.length > 0 ? (
              // zoneGridDetails.map((item, index) => {
              //   return renderItem(item, index);
              // })
              <FlatList
                numColumns={isMobile ? 2 : 4}
                // style={{ width: "75%" }}
                contentContainerStyle={{
                  padding: 5,
                }}
                data={zoneGridDetails}
                renderItem={renderItem}
              />
            ) : (
              <View style={{ justifyContent: "center" }}>
                <Text
                  style={[
                    styles.emptyListStyle,
                    { alignSelf: "center", marginTop: 20 },
                  ]}
                >
                  No Zone Data Found{" "}
                </Text>
              </View>
            )}
          </View>
        )}
      </View>
      {/* </View > */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  MainContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    flex: 1,
    padding: 20,
  },
  mapStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  card: {
    width: "25%",
    marginVertical: 10,
    padding: 10,
    borderRadius: 10,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
  },
  emptyListStyle: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
  },
});
