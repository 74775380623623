/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
// import { Ionicons } from "@expo/vector-icons/Ionicons";
import IconSafety from "react-native-vector-icons/AntDesign";
import Icon from "react-native-vector-icons/FontAwesome";
import IconMenu from "react-native-vector-icons/Entypo";
import {
  Image,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Modal,
  Button,
  Switch,
  Alert,
  Dimensions,
} from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import moment from "moment";
import { Dropdown } from "react-native-element-dropdown";

import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
  CommonActions,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useState, useRef, useEffect } from "react";
import { ColorSchemeName, Pressable } from "react-native";

import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme"; // @ts-ignore
import HomeScreen from "../screens/Home/HomeScreen";
import DeviceProfile from "../screens/Profile/DeviceProfile/DeviceProfile";

import LoginScreen from "../screens/Login/LoginScreen"; // @ts-ignore
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import ResetPass from "../screens/ResetPassword/ResetPass"; // @ts-ignore
import Sidebar from "./sidebar";
import SidebarNew from "./sidebarNew";
import SidebarDrawer from "./SidebarDrawer";
import IconRefresh from "react-native-vector-icons/FontAwesome";

import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import SubZoneGroup from "../screens/Profile/SubzoneProfile/SubzoneProfile";
import ZoneGroup from "../screens/Profile/ZoneGroup";
import ProjectProfile from "../screens/Profile/ProjectProfile/ProjectProfile";
import FarmerProfile from "../screens/Profile/FarmerProfile/FarmerProfile";
import CreateFarmerProfile from "../screens/Profile/FarmerProfile/CreateFarmerProfile";

import EditFarmerProfile from "../screens/Profile/FarmerProfile/EditFarmerProfile";

import Country from "../screens/Masters/CountryMaster/Country";
import City from "../screens/Masters/CityMaster/City";
import Tahasil from "../screens/Masters/TahasilMaster/Tahasil";
import States from "../screens/Masters/StateMaster/States";
import DocumentType from "../screens/Masters/DocumentTypeMaster/DocumentType";
import UserType from "../screens/Masters/UserTypeMaster/UserType";
import ProjectType from "../screens/Masters/ProjectTypeMaster/ProjectType";
import UserMasters from "../screens/Masters/UserMaster/UserMasters";
import AccessMaster from "../screens/AccessMaster/UserAccess";

import Village from "../screens/Masters/VillageMaster/Village";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CreateDevice from "../screens/Profile/DeviceProfile/CreateDeviceProfile";
import CreateUserMaster from "../screens/Masters/UserMaster/CreateUserMaster";
import { postData, baseUrlimage, baseUrl } from "../networking/api";
import IconEdit from "react-native-vector-icons/FontAwesome";
import IconLogout from "react-native-vector-icons/AntDesign";
import EditUserMaster from "../screens/Masters/UserMaster/EdiUserMaster";
import AlertScreen from "../screens/Alerts/AlertScreen";
import ProjectLevel from "../screens/Masters/ProjectLevel/ProjectLevel";
import ProjectLevelView from "../screens/Masters/ProjectLevel/ProjectLevelView";
import EditProjectProfile from "../screens/Profile/ProjectProfile/EditProjectprofile";
import CreateProjectProfile from "../screens/Profile/ProjectProfile/CreateProjectProfile";
import SubzoneProfile from "../screens/Profile/SubzoneProfile/SubzoneProfile";
import EditSubzoneProfile from "../screens/Profile/SubzoneProfile/EditSubZoneProfile";
import CreateSubzoneProfile from "../screens/Profile/SubzoneProfile/CreateSubzoneProfile";
import ZoneProfile from "../screens/Profile/ZoneProfile/ZoneProfile";
import CreateZoneProfile from "../screens/Profile/ZoneProfile/CreateZoneProfile";
import EditZoneProfile from "../screens/Profile/ZoneProfile/EditZoneProfile";
import CreateDeviceProfile from "../screens/Profile/DeviceProfile/CreateDeviceProfile";
import EditDeviceProfile from "../screens/Profile/DeviceProfile/EditDeviceProfile";
import ScheduleProgram from "../screens/ScheduleProgram/ScheduleProgram";
import ViewSubzoneProfile from "../screens/Profile/SubzoneProfile/ViewSubzoneProfile";
import ViewZoneProfile from "../screens/Profile/ZoneProfile/ViewZoneProfile";
import ViewProjectProfile from "../screens/Profile/ProjectProfile/ViewProjectprofile";
import UserAccess from "../screens/AccessMaster/UserAccess";
import ProjectAccess from "../screens/AccessMaster/ProjectAccess";
import SchedularMapView from "../screens/ScheduleProgram/SchedularMapView";
import GridView from "../screens/Monitors/GridView";

import MapView from "../screens/Monitors/MapView";
import UploadSchedule from "../screens/ScheduleProgram/UploadSchedule";
import DownloadSchedule from "../screens/ScheduleProgram/DownloadSchedule";
import OmegaDevice from "../screens/Masters/OmegaDevice/OmegaDevice";
import Program from "../screens/Reports/Program";
import ValveAccumulation from "../screens/Reports/ValveAccumulation";
import WaterAccumulation from "../screens/Reports/WaterAccumulation";
import Sensor from "../screens/Reports/Sensor";
import EnergyLog from "../screens/Reports/EnergyLog";
import { useSelector, useDispatch } from "react-redux";
import {
  projectIdUpdate,
  projectCodes,
  projectName,
  projectIdCreate,
} from "../components/redux/actions";
import { ActivityIndicator } from "react-native-paper";
import Zone from "../screens/Monitors/Zone";
import Subzone from "../screens/Monitors/Subzone";
import ValveView from "../screens/Monitors/ValveView";
import ValveDetailsReport from "../screens/Home/ValveDetailsReport";
import SensorDetailsReport from "../screens/Home/SensorDetailsReport";
import DeviceDetailsReport from "../screens/Home/DeviceDetailsReport";
import { ScrollView } from "react-native-gesture-handler";
import EditFarmerDetails from "../screens/FarmerDetails/EditFarmerDetails";
import Feedback from "../screens/Feedback/Feedback";
import DigitalDetailsReport from "../screens/Home/DigitalDetailsReport";
import DigitalInputsReport from "../screens/Home/DigitalInputsReport";
import EnergyStatusDetailReport from "../screens/Home/EnergyStatusDetailReport";
import SuccessPopup from "../components/SuccessPopup";

// omega
import GridViewOmega from "../screens/MonitorOmega/GridViewOmega";
import ZoneOmega from "../screens/MonitorOmega/ZoneOmega";
import SubzoneOmega from "../screens/MonitorOmega/SubzoneOmega";
import { projectBasedOn } from "../screens/ProjecBasedOn";

export default function Header({
  colorScheme,
  navigation,
}: {
  colorScheme: ColorSchemeName;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [issuccessVisible, setissuccessVisible] = useState(false);
  const [responsestatus, setresponsestatus] = useState("");
  const [PopupMessage, setPopupMessage] = useState("");
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [farmerName, setFarmerName] = useState("");

  const [userId, setUserId] = useState("");
  const [projectId, setprojectId] = useState("");

  const [profileImage, setProfileImage] = useState("");
  const [projectProfile, setProjectProfile] = useState(null);

  const [projectTitle, setProjectTitle] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [clientName, setClientName] = useState("");

  const [projectSelected, setProjectSelected] = useState("");
  var dispatch = useDispatch();
  const [isAdd, setisAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);

  var alpha_pattern = new RegExp(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/);

  const [ProjectTypeNameData, setProjectTypeNameData] = useState([]);
  const [ProjectsData, setProjectData] = useState([]);
  const [projectTypeValue, setProjectTypeValue] = useState("");
  const [projectTypeValId, setProjectTypeValueId] = useState("");

  const [projectValue, setProjectValue] = useState("");
  const [projectValId, setProjectValueId] = useState("");
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const [userTypeValue, setUserTypeValue] = useState("");
  useEffect(async () => {
    getAdminData();
  }, []);
  const buttonPressed = useSelector((state) => state.isUpdating);
  useEffect(() => {
    getAdminData();
  }, [buttonPressed]);

  const getAdminData = async () => {
    let jsonValue = await AsyncStorage.getItem("userData");

    let userTokenData = await AsyncStorage.getItem("userToken");
    let projValue = await AsyncStorage.getItem("projectData");
    // let projectIDS = await AsyncStorage.getItem("projectids");
    let userTypes = await AsyncStorage.getItem("userTypes");
    console.log("userTypes...Header", userTypes);
    var userDetailsresponse = JSON.parse(jsonValue);
    console.log("userDetailsresponse...........", userDetailsresponse);
    setUserId(userDetailsresponse.id);
    getProjectTypeList(userDetailsresponse.id);
    // // console.log("jsonValue & projValue:", jsonValue, projValue);
    var default_project_id = "";
    if (userTypes === "1") {
      // Admin
      default_project_id = userDetailsresponse.project_selected;
    } else {
      // Farmer or other user type
      default_project_id = userDetailsresponse.project_id;
      console.log("Farmer_project_selected_id", default_project_id);
    }
    if (projValue != null) {
      // console.log("project id select", projValue);
      console.log("userTypes...Header", userTypes);
      setUserTypeValue(userTypes);
      setprojectId(projValue);
      getProjectProfileById(projValue);

      // getProjectProfileById(userTypes == "1" ? projValue : projectIDS);
    } else if (jsonValue != null) {
      var userDetailsresponse = JSON.parse(jsonValue);
      console.log("userDetailsresponse==>1", userDetailsresponse);
      getProjectTypeList(userDetailsresponse.id);
      console.log("project_selected", userDetailsresponse.project_id);
      setprojectId(default_project_id);
      setUserTypeValue(userTypes);
      // getProjectProfileById(
      //   userTypes == "1"
      //     ? userDetailsresponse.project_selected
      //     : userDetailsresponse.project_id
      // );
      getProjectProfileById(default_project_id);
    }

    // console.log("userTokenData", userTokenData);

    if (userTokenData === null) {
      navigation.navigate("Login");
    } else {
      if (jsonValue != null) {
        var userDetailsresponse = JSON.parse(jsonValue);
        // console.log("userDetailsresponse==>2", userDetailsresponse);
        // setFarmerName(userDetailsresponse.farmer_name);
        setUsername(
          userDetailsresponse.first_name.charAt(0).toUpperCase() +
          userDetailsresponse.first_name.slice(1) +
          " " +
          userDetailsresponse.last_name.charAt(0).toUpperCase() +
          userDetailsresponse.last_name.slice(1)
        );
        setProfileImage(
          baseUrlimage +
          "uploads/profile_picture/" +
          userDetailsresponse.profile_pic
        );

        setUserId(userDetailsresponse.id);
        //console.log("project_selected...",userDetailsresponse.project_selected);

        setprojectId(default_project_id);

        // setLoginToken(userTokenData);

        // // console.log("isLoginToken", isLoginToken)
        // console.log("userDetailsresponse.token", userDetailsresponse.token);
        // if (userDetailsresponse.token === null) {
        //   navigation.navigate('Login')
        // }
        // setProjectTitle("Project 1");
        // setProjectManager("Mr.Basu");

        // getProjectProfileById(userDetailsresponse.project_selected);
        setProjectSelected(default_project_id);
        if (default_project_id === null) {
          setIsVisible(true);
          setisAdd(true);
        } else {
          setIsVisible(false);
          setisAdd(false);
          // setProjectSelected(projectValId);
          // console.log("data got");
        }
      }
    }
  };
  // dashboard data when click on refresh button
  const getDashboardRefresh = async () => {
    setIsLoadingSync(true);
    let projValue = await AsyncStorage.getItem("projectData");
    let jsonValue = await AsyncStorage.getItem("userData");
    var userDetailsresponse = JSON.parse(jsonValue);
    console.log("projValue", projValue);
    try {
      var dashSyncData = JSON.stringify({
        project_id:
          projValue !== null ? projValue : userDetailsresponse.project_selected,
      });
      console.log("dashSyncData payload", dashSyncData);
      const dashSyncResponse = await postData(
        dashSyncData,
        "masters/liveMonitoring"
      );
      setIsLoadingSync(false);
      var code = dashSyncResponse.code;
      if (code == 1) {
        // console.log("dashboard response", dashSyncResponse.message);
        // //alert(dashSyncResponse.message);
        setPopupMessage("Data synchronization Successfully");
        setissuccessVisible(true);
        setresponsestatus("success");
        setIsVisible(false);
      } else {
        setIsLoadingSync(false);
        setissuccessVisible(true);
        setresponsestatus("failed");
        setPopupMessage(dashSyncResponse.message);
        // //alert(dashSyncResponse.message);
      }
    } catch (error) {
      setIsLoadingSync(false);
      // console.log(error);
    }
  };

  //Get project profile  data by id
  const getProjectProfileById = async (id) => {
    console.log("INSIDE VIEW OF PROJECT PROFILE", id);
    setIsLoading(true);
    try {
      var ProfileData = JSON.stringify({
        module: "project",
        relation: [
          {
            module: "project_type",
          },
          {
            module: "project_devices",
            subModule: "devices_master",
          },
        ],
        id: id,
      });
      // console.log("ProfileData id", ProfileData);

      const ProjectProfileDataById = await postData(
        ProfileData,
        "/masters/getMasterList"
      );
      setIsLoading(false);
      var code = ProjectProfileDataById.code;
      // console.log("Project profile data====>", ProjectProfileDataById);
      if (code == 1) {
        var profileUrl =
          baseUrlimage +
          "uploads/project_profile/" +
          ProjectProfileDataById.data[0].profile_pic;
        setProjectProfile(profileUrl);
        console.log(
          "project_name",
          ProjectProfileDataById.data[0].project_name
        );
        setProjectTitle(ProjectProfileDataById.data[0].project_name);
        setProjectManager(ProjectProfileDataById.data[0].contractor);
        setClientName(ProjectProfileDataById.data[0].client);
        dispatch(projectCodes());
        dispatch(projectName());
        AsyncStorage.setItem(
          "projectCode",
          JSON.stringify(ProjectProfileDataById.data[0].project_code)
        );
        AsyncStorage.setItem(
          "projectName",
          JSON.stringify(ProjectProfileDataById.data[0].project_name)
        );
      } else {
        setIsLoading(false);
        // //alert(ProjectProfileDataById.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const isAddable = () => {
    setIsVisible(true);
    setisAdd(false);
  };

  const getProjectTypeList = (userid) => {
    //GET request
    fetch(baseUrl + "masters/userwiseProjectType/" + userid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "",
      },
      //Request Type
    })
      .then((response) => response.json())
      //If response is in json then in success
      .then((responseJson) => {
        //Success
        // //alert(JSON.stringify(responseJson));
        console.log("responseJson===>", responseJson);

        setProjectTypeNameData(responseJson.data);
      })
      //If response is not in json then in error
      .catch((error) => {
        //Error
        // //alert(JSON.stringify(error));
        console.error("valve error", error);
      });
  };

  // Get dependent project by project type
  const getDependentProjectTypeMaster = async (project_type_id) => {
    console.log("selected id", project_type_id);
    setIsLoading(true);

    try {
      var dependentData = JSON.stringify({
        user_id: userId,
        project_type_id: project_type_id,
        // data: {
        //   column: "project_type",
        //   value: project_type_id,
        // },
      });
      // console.log("dependentData===>", dependentData);
      const dependentProjectTypeDataResponse = await postData(
        dependentData,
        "masters/getProjectDataByUserId"
      );
      setIsLoading(false);
      var code = dependentProjectTypeDataResponse.code;
      if (code == 1) {
        // setCountryLength(dependentProjectTypeDataResponse.data.length);
        setProjectData(dependentProjectTypeDataResponse.data);
      } else {
        setIsLoading(false);
        // //alert(dependentProjectTypeDataResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  const renderProjectTypeItem = (item: any) => {
    // console.log("item---value", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.type}</Text>
        {item.id === projectTypeValId && (
          <IconSafety
            style={styles.icon}
            color="black"
            name="Safety"
            size={20}
          />
        )}
      </View>
    );
  };

  const renderProjectItem = (item: any) => {
    // console.log("item---value project name", item.id);
    return (
      <View style={styles.item}>
        <Text style={styles.textItem}>{item.project_name}</Text>
        {item.id === projectValId && (
          <IconSafety
            style={styles.icon}
            color="black"
            name="Safety"
            size={20}
          />
        )}
      </View>
    );
  };

  const CheckEnteredData = async () => {
    if (projectTypeValue == "" && projectValue == "") {
      setError("Please select project type");
      // console.log("empty");
      // setIsVisible(false);
    } else if (projectTypeValue && projectValue) {
      {
        isAdd == true && createProjectType();
        setIsLoading(true);
        setIsVisible(false);
      }

      {
        isAdd == false && updateProjectType();
        setIsVisible(false);
      }
    } else {
      // console.log("falseśśś");
      // setError("Please enter Character Only.");
    }
  };

  //Project Type create API
  const createProjectType = async () => {
    setIsLoading(true);
    try {
      var projectTypeData = JSON.stringify({
        module: "user_master",
        id: userId,
        data: {
          project_selected: projectValId,
        },
      });

      // console.log("Project Type:", projectTypeData);
      const projectTypeResponse = await postData(
        projectTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = projectTypeResponse.code;
      // console.log("projectTypeResponse", projectTypeResponse);
      if (code == 1) {
        // //alert(projectTypeResponse.message);
        setIsVisible(false);
        // getProjectTypeList();
        setProjectTypeValue("");
        // dispatch(projectIdCreate())

        AsyncStorage.setItem("projectSelect", JSON.stringify(projectValId));
        setProjectSelected(projectValId);

        // console.log("Added", projectTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(projectTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  //project type Update API
  const updateProjectType = async () => {
    setIsLoading(true);
    try {
      var ProjectTypeData = JSON.stringify({
        module: "user_master",
        id: userId,
        data: {
          project_selected: projectValId,
        },
      });

      await AsyncStorage.setItem("projectData", JSON.stringify(projectValId));
      getProjectProfileById(projectValId);
      console.log("project_type payload update", projectValId);
      const ProjectTypeResponse = await postData(
        ProjectTypeData,
        "masters/masterCreateUpdate"
      );

      setIsLoading(false);

      var code = ProjectTypeResponse.code;
      if (code == 1) {
        // //alert(ProjectTypeResponse.message);
        // navigation.navigate("Home")
        window.location.href = "/dashboard";
        setIsVisible(false);
        getProjectTypeList(userId);

        dispatch(projectIdUpdate());

        // setProjectSelected(ProjectTypeResponse.data[0].id)
        console.log("Updated ProjectTypeResponse", ProjectTypeResponse.data);
      } else {
        setIsLoading(false);
        // //alert(ProjectTypeResponse.message);
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  function ProjectTypeModal() {
    return (
      <>
        <Modal
          onRequestClose={() => setIsVisible(false)}
          transparent
          visible={isVisible}
        >
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "rgba(100,100,100, 0.5)",
            }}
          >
            <View
              style={{
                backgroundColor: "white",
                borderColor: "#eee",
                borderRadius: 10,
                borderWidth: 1,
                height: "40%",
                margin: "auto",
                padding: 30,
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,

                width: "30%",
              }}
            >
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "500" }}>Project Type</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectTypeNameData}
                      search
                      maxHeight={300}
                      labelField="type"
                      valueField="type"
                      placeholder="Select Project Type"
                      searchPlaceholder="Search..."
                      value={projectTypeValue}
                      onChange={(item) => {
                        setProjectTypeValue(item.type);
                        setProjectTypeValueId(item.id);
                        setProjectValue("");
                        getDependentProjectTypeMaster(item.id);
                        // console.log("value===>", item.type);
                      }}
                      renderItem={renderProjectTypeItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
                {isAdd == false && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectTypeNameData}
                      search
                      maxHeight={300}
                      labelField="type"
                      valueField="type"
                      placeholder="Select Project Type"
                      searchPlaceholder="Search..."
                      value={projectTypeValue}
                      onChange={(item) => {
                        setProjectTypeValue(item.type);
                        setProjectTypeValueId(item.id);
                        setProjectValue("");
                        getDependentProjectTypeMaster(item.id);
                        // console.log("value===> project value", item.type);
                      }}
                      renderItem={renderProjectTypeItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
              </View>
              <View style={{ width: "100%" }}>
                <Text style={{ fontWeight: "500" }}>Project</Text>
                {isAdd == true && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectsData}
                      search
                      maxHeight={300}
                      labelField="project_name"
                      valueField="project_name"
                      placeholder="Select Project"
                      searchPlaceholder="Search..."
                      value={projectValue}
                      onChange={(item) => {
                        setProjectValue(item.project_name);
                        setProjectValueId(item.id);

                        // console.log("project name", item);

                        // console.log("value===> project name", item.id);
                      }}
                      renderItem={renderProjectItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}

                {isAdd == false && (
                  <View>
                    <Dropdown
                      style={styles.dropdown}
                      placeholderStyle={styles.placeholderStyle}
                      selectedTextStyle={styles.selectedTextStyle}
                      inputSearchStyle={styles.inputSearchStyle}
                      iconStyle={styles.iconStyle}
                      data={ProjectsData}
                      search
                      maxHeight={300}
                      labelField="project_name"
                      valueField="project_name"
                      placeholder="Select Project"
                      searchPlaceholder="Search..."
                      value={projectValue}
                      onChange={(item) => {
                        setProjectValue(item.project_name);
                        setProjectValueId(item.id);
                      }}
                      renderItem={renderProjectItem}
                    />
                    <Text
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "600",
                        width: "70%",
                        marginLeft: 0,
                      }}
                    >
                      {error}
                    </Text>
                  </View>
                )}
              </View>
              {isAdd == true && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                    marginTop: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Save
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
              {isAdd == false && (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginHorizontal: 20,
                    marginTop: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => CheckEnteredData()}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Update
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#006EB9",
                      padding: 10,
                      borderWidth: 1,
                      borderColor: "#006EB9",
                      width: "50%",
                      marginRight: 10,
                    }}
                    onPress={() => setIsVisible(false)}
                  >
                    <Text style={{ color: "#fff", fontWeight: "600" }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              )}

              {/* <Gap />
          {isVisible ? <Modalception depth={depth + 1} /> : null}
          <Gap /> */}
            </View>
          </View>
        </Modal>
      </>
    );
  }
  const logout = () => {
    AsyncStorage.clear();
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: "Login" }],
      })
    );
  };
  const screenwidth = Dimensions.get("window").width;
  const isMobile = screenwidth < 768;

  return (
    <View
      style={{ flexDirection: "row", backgroundColor: "#fff", height: "100%" }}
    >
      <View>{userTypeValue == "1" && <SidebarDrawer />}</View>

      <ScrollView
        contentContainerStyle={{
          // flex: 1,
          alignItems: "flex-start", // ignore this - we'll come back to it
          justifyContent: "flex-start", // ignore this - we'll come back to it
          flexDirection: "row",
          backgroundColor: "#fff",
          // height:10
          //   padding: 10,
        }}
      >
        {/* {isLoading} */}
        {/* Sidebar  */}
        {/* <Sidebar /> */}
        {/* <SidebarNew /> */}
        {/* {userTypeValue == "1" && <SidebarDrawer />} */}
        {/* Header  */}
        <View
          style={{
            flex: 1,
            // borderWidth:6,
            // display: 'flex',
            // flexDirection: 'column'
          }}
        >
          <View style={{ flex: 1, height: "50%", backgroundColor: "#EDEDED" }}>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <Text style={{ margin: 5, color: "#7E7E7E" }}>
                <Icon name="calendar" size={20} color="#7E7E7E" />{" "}
                {moment().format("Do MMM YYYY")}
              </Text>
              <Text style={{ margin: 5, color: "#7E7E7E" }}>
                <Icon name="clock-o" size={20} color="#7E7E7E" />{" "}
                {moment().format("LT")}
              </Text>
            </View>
          </View>
          {/* start of Header title */}
          {projectSelected == null ? (
            <View
              style={{
                // flex: 2,
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#009DF0",
                justifyContent: "flex-start",
                // marginBottom: "30%",
              }}
            >
              {/* End of project title */}

              {/* Username  */}
              <View
                style={{
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {/* <Image
                  source={userIcon}
                  style={{
                    height: 38,
                    width: 28,
                    margin: 5,
                  }}
                /> */}
                  <Image
                    source={
                      profileImage
                        ? { uri: profileImage }
                        : require("../assets/images/projectImg.png")
                    }
                    // source={{ uri: profileImage }}
                    style={{ width: 28, height: 38, margin: 6 }}
                  />
                </View>
                <View>
                  <Text style={{ margin: 15, color: "#fff" }}>{username} </Text>
                </View>
              </View>
              {/* username end */}
            </View>
          ) : isLoadingProject ? (
            <ActivityIndicator
              //visibility of Overlay Loading Spinner
              color="#1DA1F2"
              visible={isLoadingProject}
              //Text with the Spinner
              textContent={"Loading..."}
              size={40}
            //  //Text style of the Spinner Text
            //  textStyle={styles.spinnerTextStyle}
            />
          ) : (
            <View
              style={isMobile ? styles.mobileContainer : styles.topBarContainer}
            >
              <View
                style={
                  isMobile
                    ? { marginLeft: 5 }
                    : {
                      marginRight: 10,
                      marginLeft: 5,
                    }
                }
              >
                <Image
                  source={
                    projectProfile
                      ? projectProfile
                      : require("../assets/images/projectImg.png")
                  }
                  style={
                    isMobile
                      ? {
                        height: 20,
                        width: 19,
                        margin: 2,
                      }
                      : {
                        height: 44,
                        width: 43,
                        margin: 5,
                      }
                  }
                />
              </View>
              <View
                style={
                  isMobile
                    ? styles.ProjectName
                    : {
                      flex: 0.6,
                      borderRightWidth: 2,
                      borderRightColor: "#fff",
                      marginTop: 5,
                      marginBottom: 5,
                    }
                }
              >
                <Text
                  // onPress={() => setIsVisible(true)}
                  style={
                    isMobile
                      ? { marginTop: -3, fontSize: 12, color: "#fff" }
                      : { margin: 2, color: "#fff" }
                  }
                >
                  {projectTitle}
                  {username == "" ? null : (
                    <TouchableOpacity onPress={() => isAddable()}>
                      <IconEdit
                        name="pencil"
                        size={isMobile ? 10 : 13}
                        color="#fff"
                        style={{
                          padding: 6,
                          // backgroundColor: "#08588E",
                          // borderRadius: 12,
                        }}
                      />
                    </TouchableOpacity>
                  )}
                </Text>
                <Text
                  // onPress={() => setIsVisible(true)}
                  style={
                    isMobile
                      ? { marginTop: -3, fontSize: 12, color: "#fff" }
                      : { margin: 1, color: "#fff" }
                  }
                >
                  {clientName}
                </Text>
              </View>
              <View
                style={
                  isMobile
                    ? {
                      marginLeft: -130,
                      marginTop: 38,
                    }
                    : {
                      // flex: 0.5,
                      marginTop: 3,
                      borderRightWidth: 2,
                      borderRightColor: "#fff",
                    }
                }
              >
                <Text
                  onPress={() => setIsVisible(true)}
                  style={
                    isMobile
                      ? { fontSize: 12, color: "#fff" }
                      : { margin: 15, color: "#fff" }
                  }
                >
                  Managed By {projectManager}
                </Text>
              </View>
              <View
                style={
                  isMobile
                    ? {
                      marginLeft: 40,
                      marginRight: -40,
                    }
                    : {
                      flex: 1,
                      marginTop: 3,
                      flexDirection: "column",
                    }
                }
              >
                {isLoadingSync ? (
                  <ActivityIndicator
                    //visibility of Overlay Loading Spinner
                    color="#fff"
                    visible={isLoadingSync}
                    //Text with the Spinner
                    textContent={"Loading..."}
                    size={40}
                  //  //Text style of the Spinner Text
                  //  textStyle={{color:'#fff'}}
                  />
                ) : (
                  <IconRefresh
                    style={{ margin: 15 }}
                    color="#fff"
                    name="refresh"
                    size={20}
                    onPress={() => getDashboardRefresh()}
                  />
                )}
              </View>

              {/* End of project title */}

              {/* Username  */}
              <View
                style={
                  isMobile
                    ? {
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginTop: 3,
                    }
                    : {
                      justifyContent: "flex-end",
                      flexDirection: "row",
                      marginTop: 3,
                    }
                }
              >
                <View
                  style={
                    isMobile
                      ? {
                        // backgroundColor: "black",
                        height: 30,
                        marginLeft: 40,
                      }
                      : {
                        flex: 1,
                        alignItems: "center",
                      }
                  }
                >
                  {/* <Image
                  source={userIcon}
                  style={{
                    height: 38,
                    width: 28,
                    margin: 5,
                  }}
                  /> */}
                  <Image
                    // source={{ uri: profileImage }}

                    source={
                      profileImage
                        ? { uri: profileImage }
                        : require("../assets/images/pricon.png")
                    }
                    style={{ width: 28, height: 38, margin: 6 }}
                  />
                </View>
                <View
                  style={
                    isMobile
                      ? {
                        marginTop: -10,
                        marginRight: 10,
                        marginLeft: -40,
                      }
                      : {
                        borderRightWidth: 2,
                        borderRightColor: "#fff",
                        // marginTop: 5,
                        // marginBottom: 5,
                      }
                  }
                >
                  <Text style={{ margin: 15, color: "#fff" }}>{username} </Text>
                </View>
                <View
                  style={
                    isMobile
                      ? {
                        marginLeft: -100,
                        marginTop: 30,
                        marginRight: 10,
                      }
                      : {
                        // flex: 1,
                        margin: 15,
                      }
                  }
                >
                  <Text
                    onPress={() => logout()}
                    // onPress={() => navigation.navigate("Login")}
                    style={{ color: "#fff", marginTop: -3 }}
                  >
                    <TouchableOpacity
                      onPress={() => logout()}

                    // onPress={() => navigation.navigate("Login")}
                    >
                      <IconLogout
                        name="poweroff"
                        size={13}
                        color="#fff"
                        style={{
                          padding: 6,
                          // backgroundColor: "#08588E",
                          // borderRadius: 12,
                        }}
                      />
                    </TouchableOpacity>
                    Logout
                  </Text>
                </View>
              </View>
              {/* username end */}
            </View>
          )}

          {/* <RootNavigator /> */}
          <View style={{ flex: 1, backgroundColor: "#fff", marginTop: 0 }}>
            {/* {isLoadingSync == true &&
                window.location.reload()
              } */}
            <RootNavigator />
            <ProjectTypeModal />
            {issuccessVisible && (
              <SuccessPopup
                visible={issuccessVisible}
                PopupMessage={PopupMessage}
                Responsestatus={responsestatus}
                onChange={() => setissuccessVisible(false)}
              />
            )}

            {/* <Text>dsfsdf</Text> */}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
const Stack = createNativeStackNavigator<RootStackParamList>();

function GridViewStack() {
  const basedOnData = projectBasedOn;
  console.log('basedOnData', basedOnData);

  return (
    <>
      {basedOnData === 1 ? (
        <Stack.Navigator initialRouteName="GridView">


          <Stack.Screen
            name="GridView"
            component={GridViewOmega}
            options={{
              title: "Monitoring Grid View -Zone",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Zone"
            component={ZoneOmega}
            options={{
              title: "Monitoring Grid View -Subzone",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Subzone"
            component={SubzoneOmega}
            options={{
              title: "Monitoring Grid View -Device",
              headerShown: false,
            }}
          />

          <Stack.Screen
            name="ValveView"
            component={ValveView}
            options={{ title: "Valve View", headerShown: false }}
          />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator initialRouteName="GridView">

          <Stack.Screen
            name="GridView"
            component={GridView}
            options={{
              title: "Monitoring Grid View -Zone",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Zone"
            component={Zone}
            options={{
              title: "Monitoring Grid View -Subzone",
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="Subzone"
            component={Subzone}
            options={{
              title: "Monitoring Grid View -Device",
              headerShown: false,
            }}
          />

          <Stack.Screen
            name="ValveView"
            component={ValveView}
            options={{ title: "Valve View", headerShown: false }}
          />
        </Stack.Navigator>
      )
      }
    </>

  );
}
function ZoneViewStack() {
  return (
    <Stack.Navigator initialRouteName="Zone">
      <Stack.Screen
        name="Zone"
        component={Zone}
        options={{ title: "Zone", headerShown: false }}
      />
      <Stack.Screen
        name="Subzone"
        component={Subzone}
        options={{ title: "Subzone", headerShown: false }}
      />
    </Stack.Navigator>
  );
}

function UserMasterStack() {
  return (
    <Stack.Navigator initialRouteName="UserMaster">
      <Stack.Screen
        name="UserMaster"
        component={UserMasters}
        options={{ title: "User Creation", headerShown: false }}
      />
      <Stack.Screen
        name="CreateUserMaster"
        component={CreateUserMaster}
        options={{ title: "Create User Master", headerShown: false }}
      />
      <Stack.Screen
        name="EditUserMaster"
        component={EditUserMaster}
        options={{ title: "Edit User Master", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function DeviceProfileStack() {
  return (
    <Stack.Navigator initialRouteName="DeviceProfile">
      <Stack.Screen
        name="DeviceProfile"
        component={DeviceProfile}
        options={{ title: "Device Profile", headerShown: false }}
      />
      <Stack.Screen
        name="CreateDeviceProfile"
        component={CreateDeviceProfile}
        options={{ title: "Create Device Profile", headerShown: false }}
      />
      <Stack.Screen
        name="EditDeviceProfile"
        component={EditDeviceProfile}
        options={{ title: "Edit Device Profile", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function SubzoneProfileStack() {
  return (
    <Stack.Navigator initialRouteName="SubzoneProfile">
      <Stack.Screen
        name="SubzoneProfile"
        component={SubzoneProfile}
        options={{ title: "SubZone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="CreateSubzoneProfile"
        component={CreateSubzoneProfile}
        options={{ title: "Create Subzone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="EditSubzoneProfile"
        component={EditSubzoneProfile}
        options={{ title: "Edit subzone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="ViewSubzoneProfile"
        component={ViewSubzoneProfile}
        options={{ title: "View subzone Profile", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function ZoneProfileStack() {
  return (
    <Stack.Navigator initialRouteName="ZoneProfile">
      <Stack.Screen
        name="ZoneProfile"
        component={ZoneProfile}
        options={{ title: "Zone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="CreateZoneProfile"
        component={CreateZoneProfile}
        options={{ title: "Create Zone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="EditZoneProfile"
        component={EditZoneProfile}
        options={{ title: "Edit Zone Profile", headerShown: false }}
      />
      <Stack.Screen
        name="ViewZoneProfile"
        component={ViewZoneProfile}
        options={{ title: "View Zone Profile", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function FarmerProfileStack() {
  return (
    <Stack.Navigator initialRouteName="FarmerProfile">
      <Stack.Screen
        name="FarmerProfile"
        component={FarmerProfile}
        options={{ title: "Farmer Profile", headerShown: false }}
      />
      <Stack.Screen
        name="CreateFarmerProfile"
        component={CreateFarmerProfile}
        options={{ title: "Create Farmer Profile", headerShown: false }}
      />
      <Stack.Screen
        name="EditFarmerProfile"
        component={EditFarmerProfile}
        options={{ title: "Edit Farmer Profile", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function FarmerDetailsStack() {
  return (
    <Stack.Navigator initialRouteName="EditFarmerDetails">
      <Stack.Screen
        name="EditFarmerDetails"
        component={EditFarmerDetails}
        options={{ title: "Edit Farmer Details", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function ProjectProfileStack() {
  return (
    <Stack.Navigator initialRouteName="ProjectProfile">
      <Stack.Screen
        name="ProjectProfile"
        component={ProjectProfile}
        options={{ title: "Project Profile", headerShown: false }}
      />
      <Stack.Screen
        name="CreateProjectProfile"
        component={CreateProjectProfile}
        options={{ title: "Create Project Profile", headerShown: false }}
      />
      <Stack.Screen
        name="EditProjectProfile"
        component={EditProjectProfile}
        options={{ title: "Edit Project Profile", headerShown: false }}
      />
      <Stack.Screen
        name="ViewProjectProfile"
        component={ViewProjectProfile}
        options={{ title: "View Project Profile", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function AccessMasterStack() {
  return (
    <Stack.Navigator initialRouteName="AccessMaster">
      <Stack.Screen
        name="UserAccess"
        component={UserAccess}
        options={{ title: "User Access", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectAccess"
        component={ProjectAccess}
        options={{ title: " Project Access", headerShown: false }}
      />
    </Stack.Navigator>
  );
}
function LevelConfiguratorScreen() {
  return (
    <Stack.Navigator initialRouteName="ProjectLevel">
      <Stack.Screen
        name="ProjectLevel"
        component={ProjectLevel}
        options={{ title: "ProjectLevel", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectLevelView"
        component={ProjectLevelView}
        options={{ title: "Level View", headerShown: false }}
      />
    </Stack.Navigator>
  );
}

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Dashboard"
        component={HomeScreen}
        options={{ title: "Dashboard", headerShown: false }}
      />
      <Stack.Screen
        name="DeviceProfile"
        component={DeviceProfileStack}
        options={{ title: "Device Profile", headerShown: false }}
      />
      <Stack.Screen
        name="SubzoneProfile"
        component={SubzoneProfileStack}
        options={{ title: "Sub Zone/Group", headerShown: false }}
      />
      <Stack.Screen
        name="ZoneProfile"
        component={ZoneProfileStack}
        options={{ title: "Zone/Group", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectProfile"
        component={ProjectProfileStack}
        options={{ title: "Project Profile", headerShown: false }}
      />
      <Stack.Screen
        name="FarmerProfile"
        component={FarmerProfileStack}
        options={{ title: "farmer Profile", headerShown: false }}
      />
      <Stack.Screen
        name="Program"
        component={Program}
        options={{ title: "Program", headerShown: false }}
      />
      <Stack.Screen
        name="ValveAccumulation"
        component={ValveAccumulation}
        options={{ title: "Valve Accumulation", headerShown: false }}
      />
      <Stack.Screen
        name="WaterAccumulation"
        component={WaterAccumulation}
        options={{ title: "Water Accumulation", headerShown: false }}
      />
      <Stack.Screen
        name="Sensor"
        component={Sensor}
        options={{ title: "Sensor", headerShown: false }}
      />
      <Stack.Screen
        name="EnergyLog"
        component={EnergyLog}
        options={{ title: "Energy Log", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectLevel"
        component={LevelConfiguratorScreen}
        options={{ title: "Level Configurator", headerShown: false }}
      />

      <Stack.Screen
        name="UserAccess"
        component={UserAccess}
        options={{ title: "User Access", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectAccess"
        component={ProjectAccess}
        options={{ title: " Project Access", headerShown: false }}
      />
      <Stack.Screen
        name="GridView"
        component={GridViewStack}
        options={{ title: "Grid view", headerShown: false }}
      />
      <Stack.Screen
        name="ValveView"
        component={ValveView}
        options={{ title: "Valve View", headerShown: false }}
      />

      <Stack.Screen
        name="Zone"
        component={ZoneViewStack}
        options={{ title: "zone view", headerShown: false }}
      />
      <Stack.Screen
        name="MapView"
        component={MapView}
        options={{ title: " Map View", headerShown: false }}
      />

      <Stack.Screen
        name="Country"
        component={Country}
        options={{ title: "Country Master", headerShown: false }}
      />
      <Stack.Screen
        name="State"
        component={States}
        options={{ title: "State Master", headerShown: false }}
      />
      <Stack.Screen
        name="City"
        component={City}
        options={{ title: "City Master", headerShown: false }}
      />
      <Stack.Screen
        name="Tahasil"
        component={Tahasil}
        options={{ title: "Tahasil Master", headerShown: false }}
      />
      <Stack.Screen
        name="Village"
        component={Village}
        options={{ title: "Village Master", headerShown: false }}
      />
      <Stack.Screen
        name="DocumentType"
        component={DocumentType}
        options={{ title: "Document Type Master", headerShown: false }}
      />
      <Stack.Screen
        name="UserType"
        component={UserType}
        options={{ title: "User Type", headerShown: false }}
      />
      <Stack.Screen
        name="UserMaster"
        component={UserMasterStack}
        options={{ title: "User Master", headerShown: false }}
      />
      <Stack.Screen
        name="AccessMaster"
        component={AccessMasterStack}
        options={{ title: "AccessMaster", headerShown: false }}
      />
      <Stack.Screen
        name="ProjectType"
        component={ProjectType}
        options={{ title: "Project Master", headerShown: false }}
      />
      <Stack.Screen
        name="OmegaDevice"
        component={OmegaDevice}
        options={{ title: "Omega Device", headerShown: false }}
      />
      <Stack.Screen
        name="Feedback"
        component={Feedback}
        options={{ title: "Feedback", headerShown: false }}
      />

      {/* <Stack.Screen
        name="ProjectLevel"
        component={ProjectLevel}
        options={{ title: "Project Level", headerShown: false }}
      /> */}
      <Stack.Screen
        name="CreateDevice"
        component={CreateDevice}
        options={{ title: "Create Device", headerShown: false }}
      />
      <Stack.Screen
        name="ScheduleProgram"
        component={ScheduleProgram}
        options={{ title: "ScheduleProgram ", headerShown: false }}
      />
      <Stack.Screen
        name="UploadSchedule"
        component={UploadSchedule}
        options={{ title: "UploadSchedule ", headerShown: false }}
      />
      <Stack.Screen
        name="DownloadSchedule"
        component={DownloadSchedule}
        options={{ title: "DownloadSchedule ", headerShown: false }}
      />
      <Stack.Screen
        name="SchedularMapView"
        component={SchedularMapView}
        options={{ title: "SchedularMapView ", headerShown: false }}
      />

      <Stack.Screen
        name="Alerts"
        component={AlertScreen}
        options={{ title: "Alert ", headerShown: false }}
      />
      <Stack.Screen
        name="ValveDetailsReport"
        component={ValveDetailsReport}
        options={{ title: "Valve View", headerShown: false }}
      />

      <Stack.Screen
        name="EnergyStatusDetailReport"
        component={EnergyStatusDetailReport}
        options={{ title: "Sensor View", headerShown: false }}
      />

      <Stack.Screen
        name="SensorDetailsReport"
        component={SensorDetailsReport}
        options={{ title: "Sensor View", headerShown: false }}
      />
      <Stack.Screen
        name="DigitalInputsReport"
        component={DigitalInputsReport}
        options={{ title: "Digital View", headerShown: false }}
      />
      <Stack.Screen
        name="DigitalDetailsReport"
        component={DigitalDetailsReport}
        options={{ title: "Digital Details", headerShown: false }}
      />

      <Stack.Screen
        name="DeviceDetailsReport"
        component={DeviceDetailsReport}
        options={{ title: "device View", headerShown: false }}
      />

      {/* <Stack.Screen
        name="EditFarmerDetails"
        component={EditFarmerDetails}
        options={{ title: "Edit Farmer Details", headerShown: false }}
      /> */}
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    // marginTop: 200,
  },
  topBarContainer: {
    // flex: 2,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#009DF0",
    justifyContent: "flex-start",
    // marginBottom: "30%",
  },

  // DropDown CSS
  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
    // borderRadius: 10,
    // padding: 12,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.2,
    // shadowRadius: 1.41,

    // elevation: 2,
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 9,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  selectedTextStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  //Responsive mobile view
  mobileContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#009DF0",
    justifyContent: "flex-start",
    height: 60,
  },
  ProjectName: {
    marginTop: -5,
    marginBottom: 5,
  },
});
