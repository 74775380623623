import {
  StyleSheet,
  TouchableOpacity,
  // TextInput,
  Button,
  ImageBackground,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RadioButton, Snackbar } from "react-native-paper";
import { Text, View } from "../../components/Themed";
import { postMethod } from "../../networking/api";
import IconCross from "react-native-vector-icons/Entypo";
import { TextInput } from "react-native-paper";
import Loader from "../loader"; // Import the loader component

export default function LoginScreen({ navigation }) {
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [emailData, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);
  const [isEmailEntered, setEmailEntered] = useState(false);
  const [isPassValid, setPassValid] = useState(false);
  const [isPassEntered, setPassEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorAlert, setErrorAlertVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [isMobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileNumError, setMobileNumError] = useState("");
  const [isPasswordSecure, setIsPasswordSecure] = useState(true);
  const regex = /^[0-9]*$/;
  const [isAssignchecked, setAssignChecked] = useState(1);

  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(/^[a-zA-Z0-9]/);
  useEffect(() => {
    setPasswordError("");
    setEmailError("");
  }, []);
  const onLoginPress = () => {
    setEmailEntered(true);
    setPassEntered(true);
    if (emailData == "") {
      setEmailError("Please Enter Email id");
    }
    if (password == "") {
      setPasswordError("Please Enter password");
    }

    if (isAssignchecked === 1) {
      // For Admin login with email
      if (!isEmailValid || !isPassValid) {
        // Check if email and password are valid
        return;
      }
      getLogin(emailData, password, 1); // Ensure the user type value is a number
    } else if (isAssignchecked === 2) {
      // For Farmer login with mobile number
      if (!isMobileNumberValid || !isPassValid) {
        // Check if mobile number and password are valid
        return;
      }
      getLogin(MobileNumber, password, 2); // Ensure the user type value is a number
    }
  };
  //Login API   // add UserType variable- RohitB
  const getLogin = async (username: string, pass: string, isAssignchecked: number) => {
    setIsLoading(true);
    try {
      const loginData = JSON.stringify({
        username: username,
        password: pass,
        userType: isAssignchecked,
      });
      const LogCustomerResponse = await postMethod(loginData, "auth/login/userFarmerLogin");
      setIsLoading(false);
      const code = LogCustomerResponse.code;
      if (code == 1) {
        try {
          await AsyncStorage.setItem("userData", JSON.stringify(LogCustomerResponse.data));
          await AsyncStorage.setItem("userToken", LogCustomerResponse.accesstoken);
          await AsyncStorage.setItem("userTypes", JSON.stringify(LogCustomerResponse.userType));
        } catch (error) {
          console.log("Something went wrong", error);
        }
        setTimeout(() => { { navigation.navigate("Home"); } }, 0);
      } else {
        setIsLoading(false);
        setEmailError("");
        setPasswordError(LogCustomerResponse.message);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      setEmailError("");
      setPasswordError("Invalid credentials");
    }
  };

  const logError = () => {
    return (
      <View
        style={{
          position: "absolute",
          top: 111,
          right: "18%",
        }}
      >
        <Snackbar
          visible={isErrorAlert}
          onDismiss={() => setErrorAlertVisible(true)}
        >
          <View style={{ padding: 20, backgroundColor: "red" }}>
            <Text style={{ color: "#fff" }}>
              {" "}
              <IconCross color="white" name="circle-with-cross" size={20} />
              InValid Credentials
            </Text>
          </View>
        </Snackbar>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <Loader isLoading={isLoading} /> {/* Use the loader component here */}

      <View style={{ flex: 1, margin: 10 }}>
        <Image
          source={{
            uri: "https://www.bermad.com/app/uploads/Bermad-logo-new.png"
          }}
          style={styles.imageContainer}
        />
        <Text style={styles.logoText}></Text>
        {/* Add Mobile Number Field based on User Type--- RohitB */}
        <View style={styles.container}>
          <View style={styles.radioGroup}>
            {/* Admin Button */}
            <TouchableOpacity
              style={[
                styles.radioOption,
                isAssignchecked === 1 && styles.selectedRadio
              ]}
              onPress={() => setAssignChecked(1)}
            >
              <View style={[
                styles.customRadio,
                isAssignchecked === 1 && styles.selectedRadio
              ]}>
                <Text style={styles.icon}>👨‍💼</Text>
              </View>
              <Text style={styles.optionText}>Admin</Text>
            </TouchableOpacity>

            {/* Farmer Button */}
            <TouchableOpacity
              style={[
                styles.radioOption,
                isAssignchecked === 2 && styles.selectedRadio
              ]}
              onPress={() => setAssignChecked(2)}
            >
              <View style={[
                styles.customRadio,
                isAssignchecked === 2 && styles.selectedRadio
              ]}>
                <Text style={styles.icon}>🌾</Text>
              </View>
              <Text style={styles.optionText}>Farmer</Text>
            </TouchableOpacity>
          </View>
        </View>
        {isAssignchecked === 2 && (
          <View>
            <Text>Mobile No.</Text>
            <TextInput
              style={styles.loginFormTextInput}
              placeholder=""
              placeholderColor="#c4c3cb"
              value={MobileNumber}
              maxLength={10}
              keyboardType={"numeric"}
              onChangeText={(value) => {
                setMobileNumberValid(false);
                setMobileNumber(value);
                if (value.length == 0) {
                  setMobileNumError("Please enter mobile number");
                } else if (!regex.test(value)) {
                  setMobileNumError("Please enter number only");
                } else if (value.length > 0 && value.length <= 9) {
                  setMobileNumError("Please enter 10 digit number");
                } else {
                  setMobileNumberValid(true);
                  setMobileNumError("");
                }
              }}
            />
            <Text style={[styles.error_text]}>{mobileNumError}</Text>
          </View>
        )}
        {isAssignchecked === 1 && (
          <View>
            <Text>Email</Text>

            <TextInput
              placeholder=""
              placeholderColor="#c4c3cb"
              style={styles.loginFormTextInput}
              onChangeText={(value) => {
                setEmailValid(false);
                setEmailError(
                  isEmailEntered && value == "" ? "Please Enter Email id" : ""
                );
                setEmail(value);
                setButtonDisabled(true);
                if (Email_Id_pattern.test(value)) {
                  setEmailValid(true);
                  setButtonDisabled(true);
                } else {
                  setEmailError("Please Enter Email id");
                }
              }}
            />
            {EmailError && <Text style={[styles.error_text]}>{EmailError}</Text>}
          </View>
        )}

        <View>
          <Text>Password</Text>
          <TextInput
            placeholderColor="#F0F0F0"
            autoCapitalize="none"
            autoCorrect={false}
            style={styles.loginFormTextInput}
            secureTextEntry={isPasswordSecure ? true : false}
            right={
              <TextInput.Icon
                name={() => (
                  <IconCross
                    name={isPasswordSecure ? "eye-with-line" : "eye"}
                    size={20}
                    color="#000"
                  />
                )}
                onPress={() => setIsPasswordSecure(!isPasswordSecure)}
              />
            }
            onChangeText={(value) => {
              setPassValid(false);
              setPasswordError(
                isPassEntered && value == "" ? "Please Enter password" : ""
              );
              setPass(value);
              setButtonDisabled(true);
              if (password_pattern.test(value)) {
                setPassValid(true);
                setButtonDisabled(false);
              } else {
                setPasswordError("Please Enter password");
              }
            }}
          />
          <View style={{ marginTop: 0 }}>
            {passwordError && (
              <Text style={[styles.error_text]}>{passwordError}</Text>
            )}
          </View>
        </View>

        <TouchableOpacity
          style={styles.loginButton}
          onPress={onLoginPress}
        >
          {isLoading ? <Text style={styles.loginText}>Loading...</Text> : <Text style={styles.loginText}>Login</Text>}
        </TouchableOpacity>
        <Text
          onPress={() => navigation.navigate("Reset")}
          style={styles.forgotText}
        >
          Forgot Password?
        </Text>
      </View>
      <View style={{ flex: 3, height: "100%" }}>
        <Image
          source={{
            uri: "https://cloud.bermad.io/assets/__packages/components/assets/images/bg.b516f1a68a4109abb0a81b851bcbd087.jpg"
          }}
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      {logError()}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
    padding: 10,
  },
  imageContainer: {
    height: 100,
    width: "100%",
    resizeMode: "contain",
    marginVertical: 10,
  },
  loginFormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 5,
    // borderWidth: 1,
    borderColor: "#fafafa",
    backgroundColor: "#F0F0F0",
    // borderBottomWidth: 1,
    paddingLeft: 0,
    marginTop: 5,
    marginBottom: 5,
  },
  logoText: {
    fontSize: 40,
    fontWeight: "800",
    marginTop: 50,
    marginBottom: 30,
    textAlign: "center",
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  loginButton: {
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: 10,
    borderWidth: 1,
    borderColor: "#2196f3",
    marginTop: 20,
  },
  loginText: {
    color: "#fff",
    fontWeight: "600",
  },
  forgotText: {
    color: "#000",
    fontWeight: "600",
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
  // DropDown CSS

  dropdown: {
    borderColor: "#c3c3c3",
    width: "100%",
    borderWidth: 1,
    borderRadius: 2,
    height: 40,
    padding: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  item: {
    padding: 17,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
  placeholderStyle: {
    fontSize: 12,
    alignSelf: "center",
  },
  selectedTextStyle: {
    fontSize: 16,
    alignSelf: "center",
  },
  iconStyle: {
    width: 0,
    height: 0,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
    outlineStyle: "none",
  },
  lableText: {
    color: "#484848",
    fontWeight: "700",
    marginTop: 18,
  },
  labelTitleTBD: {
    color: "#707070",
    padding: 10,
    fontSize: 20,
  },
  labelTitle: {
    padding: 15,
    color: "#0089D1",
    fontWeight: "600",
    textTransform: "Capitalize",
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#444',
    marginBottom: 20,
    textAlign: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  radioOption: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 2,
    paddingHorizontal: 20,
    borderRadius: 30,
    backgroundColor: '#f9f9f9',
    marginHorizontal: 15,
    borderWidth: 1,
    borderColor: '#ccc',
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 2,
  },
  selectedOption: {
    backgroundColor: 'linear-gradient(to right, #4c8bf5, #73a8f0)',  // Gradient effect
    borderColor: '#4c8bf5',
  },
  customRadio: {
    width: 30,
    height: 30,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: '#2196F3',
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  selectedRadio: {
    backgroundColor: '#2196F3',  // Blue color when selected
    borderColor: '#2196F3',
  },
  optionText: {
    fontSize: 18,
    color: '#333',
    fontWeight: '500',
  },
  icon: {
    fontSize: 20,
    marginRight: 2,
  },
});
