import {
  StyleSheet,
  TouchableOpacity,
  // TextInput,
  Button,
  ImageBackground,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";

import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import { postMethod } from "../../networking/api";
import { TextInput } from "react-native-paper";
import IconCross from "react-native-vector-icons/Entypo";


export default function ChangePassword({ navigation }) {
  //   {
  //   navigation,
  // }: RootStackScreenProps<"Login">
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [emailData, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewPasswordSecure, setIsNewPasswordSecure] = useState(true);
  const [isConfirmPasswordSecure, setIsConfirmPasswordSecure] = useState(true);

  const [isEmailValid, setEmailValid] = useState(false);
  const [isEmailEntered, setEmailEntered] = useState(false);
  const [isPassValid, setPassValid] = useState(false);
  const [isPassEntered, setPassEntered] = useState(false);

  const [isConfirmPassValid, setConfirmPassValid] = useState(false);
  const [isConfirmPassEntered, setConfirmPassEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [Passwordderror, setPasswordError] = useState("");
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  var password_pattern = new RegExp(
    /^[a-zA-Z0-9]+$/
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&^_-]{8,}$/
  );

  var urlName = window.location.pathname;
  var id = urlName.substring(urlName.lastIndexOf('/') + 1);

  //  var urlId = 1;
  // console.log("urlname id", id);

  // console.log("urlname linking", urlName);


  const onPressChangePassword = () => {
    setPassEntered(true);
    setConfirmPassEntered(true);
    // console.log("ChangePassword====>", password);

    // console.log("confirmPassword====>", confirmPassword);
    if (isPassValid && isConfirmPassValid) {
      if (password === confirmPassword) {
        // console.log("confirmPasswords");

        getChangePassword(password);
      } else {
        setPasswordError("Confirm password should be same");
        // //alert("Confirm password should be same");
      }
    }
  };

  //Login API
  const getChangePassword = async (pass) => {
    setIsLoading(true);

    try {
      var ChangePasswordData = JSON.stringify({
        id: id,
        password: pass,
      });

      const ChangePasswordResponse = await postMethod(
        ChangePasswordData,
        "auth/login/changePassword"
      );
      // console.log("ChangePasswordData:", ChangePasswordData);
      setIsLoading(false);

      var code = ChangePasswordResponse.code;
      if (code == 1) {
        // //alert(ChangePasswordResponse.message);
        navigation.navigate("Login");
        // console.log("success log:", ChangePasswordResponse.data);
      } else {
        setIsLoading(false);
        // //alert(ChangePasswordResponse.message);
        // console.log("failed log");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, margin: 10 }}>
        <Image
          source={{
            uri: "https://www.bermad.com/app/uploads/Bermad-logo-new.png"
          }}
          style={styles.imageContainer}
        />
        <Text style={styles.logoText}></Text>
        <Text>New Password</Text>
        <TextInput
          placeholder=""
          placeholderColor="#F0F0F0"
          style={styles.loginFormTextInput}
          secureTextEntry={isNewPasswordSecure}
          right={
            <TextInput.Icon
              name={() => <IconCross name={isNewPasswordSecure ? "eye-with-line" : "eye"} size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              onPress={() => { isNewPasswordSecure ? setIsNewPasswordSecure(false) : setIsNewPasswordSecure(true) }}
            />
          }
          onChangeText={(value) => {
            setPassValid(false);

            setPass(value);
            setButtonDisabled(true);
            // // console.log("if 1", value);
            if (password_pattern.test(value)) {
              setPassValid(true);
              // // console.log("if 2", value);
              // //alert(value);
              setButtonDisabled(false);
            }
          }}

        />
        {isPassEntered && !isPassValid && (
          <Text style={[styles.error_text]}>Please Enter correct password</Text>
        )}
        <Text>Confirm Password</Text>
        <TextInput
          placeholder=""
          placeholderColor="#F0F0F0"
          style={styles.loginFormTextInput}
          secureTextEntry={isConfirmPasswordSecure}
          right={
            <TextInput.Icon
              name={() => <IconCross name={isConfirmPasswordSecure ? "eye-with-line" : "eye"} size={20} color="#000" />} // where <Icon /> is any component from vector-icons or anything else
              onPress={() => { isConfirmPasswordSecure ? setIsConfirmPasswordSecure(false) : setIsConfirmPasswordSecure(true) }}
            />
          }
          onChangeText={(value) => {
            setConfirmPassValid(false);

            setConfirmPassword(value);
            setButtonDisabled(true);
            // console.log("if 1", value);
            if (password_pattern.test(value)) {
              setConfirmPassValid(true);
              // console.log("if 2", value);
              // //alert(value);
              setButtonDisabled(false);
            }
          }}
        />
        {isConfirmPassEntered && !isConfirmPassValid && (
          <Text style={[styles.error_text]}>
            Confirm password should be same
          </Text>
        )}

        <TouchableOpacity
          disabled={isButtonDisabled}
          style={styles.loginButton}
          onPress={onPressChangePassword}
        >
          <Text style={styles.loginText}>Save</Text>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 3, height: "100%" }}>
        <Image
          source={{
            uri: "https://cloud.bermad.io/assets/__packages/components/assets/images/bg.b516f1a68a4109abb0a81b851bcbd087.jpg"
          }}
          style={{
            // flex: 1,
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
    padding: 10,
  },
  imageContainer: {
    height: 100,
    width: "100%",
    resizeMode: "contain",
    marginVertical: 10,
  },
  loginFormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 5,
    // borderWidth: 1,
    borderColor: "#fafafa",
    backgroundColor: "#F0F0F0",
    // borderBottomWidth: 1,
    paddingLeft: 0,
    marginTop: 5,
    marginBottom: 20,
  },
  logoText: {
    fontSize: 40,
    fontWeight: "800",
    marginTop: 50,
    marginBottom: 30,
    textAlign: "center",
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  loginButton: {
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: 10,
    borderWidth: 1,
    borderColor: "#2196f3",
    marginTop: 20,
  },
  loginText: {
    color: "#fff",
    fontWeight: "600",
  },
  forgotText: {
    color: "#000",
    fontWeight: "600",
    marginTop: 10,
    textAlign: "center",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
