import {
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Button,
  ImageBackground,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";

import { Text, View } from "../../components/Themed";
import { RootStackScreenProps } from "../../types"; // @ts-ignore
import { postMethod } from "../../networking/api";

export default function ResetPass({ navigation }) {
  //   {
  //   navigation,
  // }: RootStackScreenProps<"reset">
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [emailData, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [isEmailValid, setEmailValid] = useState(false);
  const [isEmailEntered, setEmailEntered] = useState(false);
  const [isPassValid, setPassValid] = useState(false);
  const [isPassEntered, setPassEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [EmailError, setemailError] = useState("");
  var Email_Id_pattern = new RegExp(
    /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/
  );
  useEffect(() => {
    setemailError("");
  }, []);

  const resetPassword = async (username) => {
    setIsLoading(true);
    // console.log("inside rest password api");
    try {
      var resetPassData = JSON.stringify({
        username: username,
      });
      // console.log("reset email", username);
      const resetPasswordResponse = await postMethod(
        resetPassData,
        "auth/login/forgotPassword/"
      );
      setIsLoading(false);
      var code = resetPasswordResponse.code;
      if (code == 1) {
        //alert(resetPasswordResponse.message);
        navigation.navigate("Login");
        // console.log("reset password log:", resetPasswordResponse.data);
      } else {
        setIsLoading(false);
        setemailError(resetPasswordResponse.message);
        // console.log("failed resent");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log(error);
    }
  };
  const onResetPress = () => {
    setEmailEntered(true);

    if (isEmailValid) {
      // console.log("onResetPress");
      // //alert(emailData);
      resetPassword(emailData);
    }
  };

  const onFbLoginPress = () => {
    // console.log("onLoginPress");
  };
  return (
    <View style={styles.container}>
      <View style={{ flex: 1, margin: 10 }}>
        <Image
          source={{
            uri: "https://www.bermad.com/app/uploads/Bermad-logo-new.png"
          }}
          style={styles.imageContainer}
        />
        <Text style={styles.logoText}></Text>
        <Text>Email</Text>

        <TextInput
          placeholder=""
          placeholderColor="#c4c3cb"
          style={styles.loginFormTextInput}
          onChangeText={(value) => {
            setEmailValid(false);
            setemailError("");
            setEmail(value);
            // setButtonDisabled(true);
            // console.log("if 1", value);
            if (Email_Id_pattern.test(value)) {
              setEmailValid(true);
              // console.log("if 2", value);
              setButtonDisabled(false);
            }
          }}
        />
        {isEmailEntered && !isEmailValid && (
          <Text style={[styles.error_text]}>
            {"Please Enter Valid Email id"}
          </Text>
        )}
        {EmailError && <Text style={[styles.error_text]}>{EmailError}</Text>}

        <TouchableOpacity style={styles.ResetButton} onPress={onResetPress}>
          <Text style={styles.resetText}>Reset</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.loginButton}>
          <Text
            style={styles.loginText}
            onPress={() => navigation.navigate("Login")}
          // onPress={() => navigation.navigate("Reset")}
          >
            Login
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 3, height: "100%" }}>
        <Image
          source={{
            uri: "https://cloud.bermad.io/assets/__packages/components/assets/images/bg.b516f1a68a4109abb0a81b851bcbd087.jpg",
          }}
          style={{
            // flex: 1,
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center", // ignore this - we'll come back to it
    justifyContent: "center", // ignore this - we'll come back to it
    flexDirection: "row",
    padding: 10,
  },
  imageContainer: {
    height: 100,
    width: "100%",
    resizeMode: "contain",
    marginVertical: 10,
  },
  ResetButton: {
    alignItems: "center",
    backgroundColor: "#2196f3",
    padding: 10,

    borderWidth: 1,
    borderColor: "#2196f3",
    marginTop: 20,
  },
  resetText: {
    color: "#fff",
    fontWeight: "600",
  },

  loginFormTextInput: {
    height: 43,
    fontSize: 14,
    borderRadius: 5,
    // borderWidth: 1,
    borderColor: "#fafafa",
    backgroundColor: "#dcd6d0",
    // borderBottomWidth: 1,
    paddingLeft: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  loginButton: {
    alignItems: "center",
    backgroundColor: "#ffff",
    padding: 10,
    borderWidth: 1,
    borderColor: "#2196f3",
    marginTop: 20,
  },
  loginText: {
    color: "#2196f3",
    fontWeight: "600",
  },
  logoText: {
    fontSize: 40,
    fontWeight: "800",
    marginTop: 50,
    marginBottom: 30,
    textAlign: "center",
  },
  error_text: {
    color: "red",
    fontSize: 14,
    fontWeight: "600",
    width: "70%",
    marginLeft: 0,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: "#2e78b7",
  },
});
